import {
  getSessionId,
  getSessionAuth,
  sessionLogout,
} from "components/shared/session";

// ------------------------------------------------
// code to handle api calls (will eventually move to another file)
// ------------------------------------------------
export function createRequest(method, data, isFile) {
  const authToken = getSessionAuth().accessToken;
  const request = {
    method,
    headers: {
      Accept: "application/json",
      "X-TTS-SESSION": getSessionId(),
      "x-access-token": authToken ? `${authToken}` : " ",
    },
  };

  if (data && isFile) {
    /* global FormData */
    const formData = new FormData();
    formData.append("file", data);
    request.body = formData;
  } else if (data && (method === "POST" || method === "PUT")) {
    request.body = JSON.stringify(data);
    request.headers["Content-Type"] = "application/json";
  }

  return request;
}

export async function apiCallAsync(url, data, method, isFile = false) {
  if (url.indexOf("/api") !== 0) {
    url = "/api" + url;
  }

  const request = createRequest(method, data, isFile);
  const response = await fetch(url, request);

  if (response.status === 401) {
    const body = await response.json();
    if (body.message && body.message.name === "TokenExpiredError") {
      sessionLogout();
      window.alert("Your session has expired, please log back in.");
      window.location.href = "https://waterscenterst.org/login";
    } else {
      throw new Error("unauthorized");
    }
  }

  if (
    response.status !== 200 &&
    response.status !== 204 &&
    response.status !== 201
  ) {
    throw new Error(await response.text());
  }

  return response.json();
}
