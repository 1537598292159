import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <div className="template-block">
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/7q_C7E6NLK4?rel=0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>

    <p>
    What can you do when you find yourself in a hard conversation? In Waters Center's November 2024 Open Studio Webinar, join Systems Thinking Specialists Melanie Houston and Alexandria Sedar in exploring this question using dialogue, conflict transformation, and systems thinking principles. Through examples, reflection, and opportunities for application, we will experience how the ladder of inference can be used to increase understanding and introduce intentionality into our communication practices.
    </p>
  </div>
);
