import * as React from "react";
import { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { CourseLayout } from "./CourseLayout";
import { courseList } from "./courseList";
import { CoursesHome } from "./coursesHome";
import { answersPageStart } from "components/shared/answers/answers.actions";
import {
  HabitsCatalog,
  ToolsCatalog,
  ArchetypesCatalog,
} from "components/courses/course-catalogs";
import { CourseEnd } from "./course-parts/course-end";
import PropTypes from "prop-types";

import "./styles.scss";

const CoursesRouterView = ({ match, isLoading, onLoad }) => {
  useEffect(() => {
    onLoad();
  }, []);

  return (
    <main>
      <Switch>
        <Route path="/courses/overview" component={CoursesHome} />
        <Route path="/courses/habits" component={HabitsCatalog} />
        <Route path="/courses/tools" component={ToolsCatalog} />
        <Route path="/courses/archetypes" component={ArchetypesCatalog} />
        <Route path="/courses/course-end" component={CourseEnd} />
        {courseList.map((c) => (
          // create route for every course path (e.g. /courses/{course-path})
          // url parts after the course path will be handled by the Switch in the CourseLayout
          <Route
            path={`${match.url}/${c.path}`}
            key={c.path}
            render={(props) => <CourseLayout {...props} course={c} />}
          />
        ))}
        {/* if no course is found, redirect to first one, for now. TODO: handle better? */}
        <Redirect to={`${match.url}/${courseList[0].path}`} />
      </Switch>
      {isLoading && <div>Loading...</div>}
    </main>
  );
};

CoursesRouterView.propTypes = {
  onLoad: PropTypes.func,
  isLoading: PropTypes.any,
  match: PropTypes.any,
};

export const CoursesRouter = connect(
  ({ answers }, ownProps) => ({
    ...ownProps,
    isLoading: answers.isLoading,
  }),
  (dispatch) => ({
    onLoad: () => dispatch(answersPageStart()),
  }),
)(CoursesRouterView);
