import * as Resource01 from "components/resources/resource-content/botg-retelling";
import * as Resource02 from "components/resources/resource-content/legacy-graphing";
import * as Resource03 from "components/resources/resource-content/host-analogies";
import * as Resource04 from "components/resources/resource-content/host-self-assessment";
import * as Resource05 from "components/resources/resource-content/water-activity";
import * as Resource06 from "components/resources/resource-content/water-hole";
import * as Resource07 from "components/resources/resource-content/creative-tension";
import * as Resource08 from "components/resources/resource-content/bean-game";
import * as Resource09 from "components/resources/resource-content/ecosystem-connection-circle";
import * as Resource10 from "components/resources/resource-content/leaders-we-admire";
import * as Resource11 from "components/resources/resource-content/host-matrix";
import * as Resource12 from "components/resources/resource-content/what-do-you-see";
import * as Resource13 from "components/resources/resource-content/drifting-goals";
import * as Resource14 from "components/resources/resource-content/iceberg-leverage-learning";
import * as Resource15 from "components/resources/resource-content/escalation";
import * as Resource16 from "components/resources/resource-content/fixes-that-backfire";
import * as Resource17 from "components/resources/resource-content/limits-to-success";
import * as Resource18 from "components/resources/resource-content/success-to-the-successful";
import * as Resource19 from "components/resources/resource-content/repression-and-revolution";
import * as Resource20 from "components/resources/resource-content/shifting-the-burden";
// import * as Resource21 from "components/resources/resource-content/story-structure";
import * as Resource22 from "components/resources/resource-content/tragedy-of-the-commons";
import * as Resource23 from "components/resources/resource-content/botg-with-questions";
import * as Resource24 from "components/resources/resource-content/connection-circle-botgs";
import * as Resource25 from "components/resources/resource-content/iceberg-3-dimensional";
import * as Resource26 from "components/resources/resource-content/iceberg-current-reality";
import * as Resource27 from "components/resources/resource-content/ladder-with-questions";
import * as Resource28 from "components/resources/resource-content/ladder-notice-act";
import * as Resource29 from "components/resources/resource-content/ladder-worksheet";
import * as Resource30 from "components/resources/resource-content/borton-boys-video";
import * as Resource31 from "components/resources/resource-content/middle-school-students";
import * as Resource32 from "components/resources/resource-content/habits-reflection-sheet";
import * as Resource33 from "components/resources/resource-content/habits-single-page";
import * as Resource34 from "components/resources/resource-content/iceberg-with-graphics";
import * as Resource35 from "components/resources/resource-content/ladder-with-graphics";
import * as Resource36 from "components/resources/resource-content/water-manager";
import * as Resource37 from "components/resources/resource-content/ladybugs-and-aphids";
import * as Resource38 from "components/resources/resource-content/curiosity";
import * as Resource39 from "components/resources/resource-content/structuring-schools";
import * as Resource40 from "components/resources/resource-content/leading-change";
import * as Resource41 from "components/resources/resource-content/developing-capacity";
import * as Resource42 from "components/resources/resource-content/st-rubrics";
import * as Resource43 from "components/resources/resource-content/books-with-st";
import * as Resource44 from "components/resources/resource-content/character-comparison";
import * as Resource45 from "components/resources/resource-content/sense-of-belonging";
import * as Resource46 from "components/resources/resource-content/to-kill-a-mockingbird";
import * as Resource47 from "components/resources/resource-content/hey-little-ant";
import * as Resource48 from "components/resources/resource-content/mirror-mirror-star";
import * as Resource49 from "components/resources/resource-content/maze-craze";
import * as Resource50 from "components/resources/resource-content/introducing-systems";
import * as Resource51 from "components/resources/resource-content/word-up";
import * as Resource52 from "components/resources/resource-content/st-math-discourse";
import * as Resource53 from "components/resources/resource-content/caring-classroom";
import * as Resource54 from "components/resources/resource-content/classroom-management";
import * as Resource55 from "components/resources/resource-content/habits-single-page-italian";
import * as Resource56 from "components/resources/resource-content/st-in-schools";
import * as Resource57 from "components/resources/resource-content/ts-seasons-salmon-storms";
import * as Resource58 from "components/resources/resource-content/os-taking-action";
import * as Resource59 from "components/resources/resource-content/story-of-rebuild";
import * as Resource60 from "components/resources/resource-content/peggy-gingerich-interview";
import * as Resource61 from "components/resources/resource-content/chris-holiday-interview";
import * as Resource62 from "components/resources/resource-content/st-for-all";
import * as Resource63 from "components/resources/resource-content/beyond-virtual";
import * as Resource64 from "components/resources/resource-content/habits-newsletters";
import * as Resource65 from "components/resources/resource-content/habits-single-page-spanish";
import * as Resource66 from "components/resources/resource-content/habits-coaching-questions";
import * as Resource67 from "components/resources/resource-content/habits-single-page-japanese";
import * as Resource68 from "components/resources/resource-content/jim-waters-tribute";
import * as Resource69 from "components/resources/resource-content/honoring-mary-scheetz";
import * as Resource70 from "components/resources/resource-content/interview-with-taylor-davidson";
import * as Resource71 from "components/resources/resource-content/school-leadership-forum-speakers";
import * as Resource72 from "components/resources/resource-content/habits-single-page-persian";
import * as Resource73 from "components/resources/resource-content/os-applied-st";
import * as Resource74 from "components/resources/resource-content/habits-single-page-german";
import * as Resource75 from "components/resources/resource-content/habits-single-page-portuguese";
import * as Resource76 from "components/resources/resource-content/os-now-what";
import * as Resource77 from "components/resources/resource-content/levels-of-commitment";
import * as Resource78 from "components/resources/resource-content/habits-sort";
import * as Resource79 from "components/resources/resource-content/foldable-botg";
import * as Resource80 from "components/resources/resource-content/os-leaders-our-systems-deserve";
import * as Resource81 from "components/resources/resource-content/os-planting-the-seeds";
import * as Resource82 from "components/resources/resource-content/habits-single-page-french";
import * as Resource83 from "components/resources/resource-content/iceberg-scientific-phenomenon";
import * as Resource84 from "components/resources/resource-content/os-imperfect-leader";
import * as Resource85 from "components/resources/resource-content/habits-single-page-dutch";
import * as Resource86 from "components/resources/resource-content/habits-single-page-finnish";
import * as Resource87 from "components/resources/resource-content/os-peacebuilding-habits";
import * as Resource88 from "components/resources/resource-content/os-fixes-that-fail";
import * as Resource89 from "components/resources/resource-content/double-ladders";
import * as Resource90 from "components/resources/resource-content/os-building-a-more-equitable-world-using-st";
import * as Resource91 from "components/resources/resource-content/habits-single-page-mandarin";
import * as Resource92 from "components/resources/resource-content/habits-single-page-turkish";
import * as Resource93 from "components/resources/resource-content/os-using-loi-to-navigate-hard-conversations";


import { NavLevel, getCurrentNavSetup } from "components/shared/navigation";

// Groups Options: template, guide, lesson, assessment, webinar, article

export const resourceList = [
  {
    groups: ["lesson"],
    path: "botg-retelling",
    name: "Using Behavior-over-time Graphs to Teach Retelling",
    icons: [
      {
        id: 1,
        name: "primary-icon",
        source: "/images/resources/shared/primary-icon.svg",
      },
    ],
    content:
      "This guide will show educators how to use BOTGs as a valuable tool in literacy.",
    audience: "Students in early childhood settings",
    concepts: "Behavior-over-time graphs, Patterns and Trends",
    sections: [
      {
        path: "botg-for-use-with-a-childrens-story",
        component: Resource01.Section01,
      },
    ],
    contentTags: [
      "for-teachers",
      "c-lp",
      "h-bp",
      "h-cp",
      "h-cot",
      "t-botg",
      "lg-ec",
      "lg-pe",
      "l-en",
    ],
  },
  {
    groups: ["guide", "lesson"],
    path: "legacy-graphing",
    name: "Legacy Graphing",
    icons: [
      {
        id: 1,
        name: "facilitation-icon",
        source: "/images/resources/shared/facilitation-icon.svg",
      },
      {
        id: 2,
        name: "primary-icon",
        source: "/images/resources/shared/primary-icon.svg",
      },
      {
        id: 3,
        name: "secondary-icon",
        source: "/images/resources/shared/secondary-icon.svg",
      },
    ],
    content:
      "This resource will show educators how students can tell a story using a behavior-over-time graph (BOTG).",
    audience: "Students of all ages",
    concepts: "Behavior-over-time graphs, change over time",
    sections: [
      {
        path: "using-botgs-for-legacy-graphing",
        component: Resource02.Section01,
      },
    ],
    contentTags: [
      "c-fg",
      "c-lp",
      "h-cot",
      "h-cp",
      "t-botg",
      "lg-pe",
      "lg-se",
      "lg-ae",
      "lg-he",
      "lg-pl",
      "l-en",
    ],
  },
  {
    groups: ["guide", "lesson"],
    path: "host-analogies",
    name: "Exploring the Habits with Analogies",
    icons: [
      {
        id: 1,
        name: "facilitation-icon",
        source: "/images/resources/shared/facilitation-icon.svg",
      },
      {
        id: 2,
        name: "secondary-icon",
        source: "/images/resources/shared/secondary-icon.svg",
      },
    ],
    content:
      "Learners will use everyday objects to make connections to the Habits of a Systems Thinker.",
    audience:
      "Groups and teams from any organization/system and secondary students",
    concepts: "All the Habits of a Systems Thinker",
    sections: [
      {
        path: "exploring-the-habits-with-analogies",
        component: Resource03.Section01,
      },
    ],
    contentTags: [
      "c-fg",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-se",
      "lg-ae",
      "lg-he",
      "lg-pl",
      "l-en",
      "for-teachers",
    ],
  },
  {
    groups: ["assessment"],
    path: "host-self-assessment",
    name: "Habits of a Systems Thinker Self-Assessment for Individuals and Groups",
    icons: [
      {
        id: 1,
        name: "assessments-icon",
        source: "/images/resources/shared/assessments-icon.svg",
      },
    ],
    content:
      "This resource will introduce the Habits of a Systems Thinker to individuals and groups through personal/group reflection and exploration.",
    audience: "Adult learners from any organization/system",
    concepts: "All the Habits of a Systems Thinker",
    sections: [
      {
        path: "habits-self-assessment-for-individuals-groups",
        component: Resource04.Section01,
      },
    ],
    contentTags: [
      "c-as",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-ae",
      "lg-he",
      "lg-pl",
      "l-en",
      "for-students",
      "for-teachers",
    ],
  },
  {
    groups: ["guide"],
    path: "water-activity",
    name: "Water Activity",
    icons: [
      {
        id: 1,
        name: "facilitation-icon",
        source: "/images/resources/shared/facilitation-icon.svg",
      },
    ],
    content:
      "This activity will help participants better understand accumulations and their rates of change.",
    audience: "Grade 3 – Adult (in any type of system)",
    concepts: "Stock-flow maps",
    sections: [
      {
        path: "water-apparatus-assembly",
        component: Resource05.Section01,
      },
      {
        path: "water-challenges",
        component: Resource05.Section02,
      },
    ],
    contentTags: [
      "l-en",
      "c-fg",
      "c-lp",
      "h-a",
      "h-cot",
      "t-sfm",
      "lg-pe",
      "lg-se",
      "lg-ae",
      "lg-he",
      "lg-pl",
      "for-teachers",
      "for-students",
      "for-leaders",
    ],
  },
  {
    groups: ["lesson"],
    path: "water-hole",
    name: "Water Hole",
    icons: [
      {
        id: 1,
        name: "primary-icon",
        source: "/images/resources/shared/primary-icon.svg",
      },
    ],
    content:
      "This lesson will help students explore accumulations and their rates of change.",
    audience: "Elementary Students",
    concepts:
      "Change over time, accumulation, behavior-over-time graph, Stock-flow map",
    sections: [
      {
        path: "water-hole-procedure",
        component: Resource06.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "h-a",
      "h-cot",
      "t-botg",
      "t-sfm",
      "lg-ec",
      "lg-pe",
      "for-teachers",
    ],
  },
  {
    groups: ["guide"],
    path: "creative-tension",
    name: "Creative Tension",
    icons: [
      {
        id: 1,
        name: "facilitation-icon",
        source: "/images/resources/shared/facilitation-icon.svg",
      },
    ],
    content:
      "This guide will help students explore the importance of gaps and small-step strategies to address gaps.",
    audience:
      "Intermediate students and groups/teams from organizations (systems) of any kind.",
    concepts:
      "Seeks to understand the big picture, Changes perspectives to increase understanding, Surfaces and tests assumptions, Successive approximation, Patterns and trends, Leverage, Behavior-over-time graphs, Causal loops",
    sections: [
      {
        path: "exercise-procedure",
        component: Resource07.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-fg",
      "h-bp",
      "h-cp",
      "h-sta",
      "h-sa",
      "h-cot",
      "h-l",
      "t-botg",
      "t-cl",
      "a-dg",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["guide"],
    path: "bean-game",
    name: "Bean Game — A Role-play Simulation",
    icons: [
      {
        id: 1,
        name: "facilitation-icon",
        source: "/images/resources/shared/facilitation-icon.svg",
      },
    ],
    content:
      "“The Bean Game” traces the effects of multi-generational families over time as each generation decides what resources they will need to live healthy, prosperous lives.",
    audience: "Secondary students - adults",
    concepts:
      "Habits of a Systems Thinker: Observes how elements within systems change over time, generating patterns and trends, considers short-term, long-term and unintended consequences of actions; Systems Thinking Tools: Behavior-over-time graphs, Iceberg; Archetypes: Tragedy of the Commons",
    sections: [
      {
        path: "exercise-procedure",
        component: Resource08.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-fg",
      "h-cot",
      "h-td",
      "h-cif",
      "h-cc",
      "h-mm",
      "h-sgb",
      "t-sfm",
      "t-ice",
      "a-totc",
      "lg-se",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["lesson"],
    path: "ecosystem-connection-circle",
    name: "Ecosystem Connection Circle",
    icons: [
      {
        id: 1,
        name: "primary-icon",
        source: "/images/resources/shared/primary-icon.svg",
      },
    ],
    content:
      "In this activity, students will explore the interrelationships and interdependencies among the various elements of a desert ecosystem.",
    audience: "Students grades 3-5",
    concepts:
      "Habits of a Systems Thinker: Seeks to understand the big picture, Identifies the circular nature of complex cause and effect relationships, Considers short-term, long-term, and unintended consequences of actions, Recognizes the impact of time delays when exploring cause and effect relationships; Systems Thinking Tools: Feedback Loops, Connection Circles",
    sections: [
      {
        path: "activity-procedure",
        component: Resource09.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-lp",
      "h-bp",
      "h-cn",
      "h-cc",
      "h-td",
      "t-cl",
      "t-cc",
      "lg-pe",
      "lg-se",
      "for-teachers",
    ],
  },
  {
    groups: ["guide"],
    path: "leaders-we-admire",
    name: "Habits of Leaders We Know and Admire",
    icons: [
      {
        id: 1,
        name: "facilitation-icon",
        source: "/images/resources/shared/facilitation-icon.svg",
      },
    ],
    content:
      "This resource will help those looking to make connections between the Habits of a Systems Thinker and desired leadership qualities for their students/colleagues.",
    audience:
      "Primarily adults, but secondary students will also benefit from this thought exercise.",
    concepts: "All the Habits of a Systems Thinker",
    sections: [
      {
        path: "exercise-procedure",
        component: Resource10.Section01,
      },
    ],
    contentTags: [
      "c-fg",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "l-en",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["guide", "assessment", "template"],
    path: "host-matrix",
    name: "Habits of a Systems Thinker Team Reflection Matrix",
    icons: [
      {
        id: 1,
        name: "assessments-icon",
        source: "/images/resources/shared/assessments-icon.svg",
      },
      {
        id: 2,
        name: "facilitation-icon",
        source: "/images/resources/shared/facilitation-icon.svg",
      },
      {
        id: 3,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "These exercises are intended to help facilitate reflection and evaluation for team members/colleagues using the Habits of a Systems thinker.",
    audience: "Adults in any type of system",
    concepts:
      "All the Habits of a Systems Thinker and all systems thinking tools",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/habits-reflection-sheet.pdf",
        label: "6-column Habits Matrix",
      },
      {
        id: 2,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/habits-matrix-8column.pdf",
        label: "8-column Habits Matrix",
      },
    ],
    sections: [
      {
        path: "exercise-procedure",
        component: Resource11.Section01,
      },
      {
        path: "specific-scenarios",
        component: Resource11.Section02,
      },
    ],
    contentTags: [
      "l-en",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "c-t",
      "c-fc",
      "lg-se",
      "lg-ae",
      "lg-he",
      "lg-pl",
      "for-teachers",
      "for-students",
      "for-leaders",
    ],
  },
  {
    groups: ["guide"],
    path: "what-do-you-see",
    name: "What Do You See?",
    icons: [
      {
        id: 1,
        name: "facilitation-icon",
        source: "/images/resources/shared/facilitation-icon.svg",
      },
    ],
    content:
      "This guide will provide a visual introduction to mental models and dual ladders of inference.",
    audience:
      "This guide can be applied to a variety of audiences of all ages: in the classroom, with teams at work, during professional development sessions and more.",
    concepts: "Mental models and ladder of inference",
    sections: [
      {
        path: "exercise-procedure",
        navName: "Exercise Procedure",
        component: Resource12.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-fg",
      "c-lp",
      "h-mm",
      "h-cp",
      "t-loi",
      "lg-pe",
      "lg-se",
      "lg-ae",
      "lg-pl",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["template"],
    path: "drifting-goals",
    name: "Archetype: Drifting Goals",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content: "Template and summary for the Drifting Goals Archetype",
    audience: "All learners",
    concepts:
      "Leverage, successive approximation, impact of time delays, considers an issue fully, causal loops",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/drifting-goals-template-so.pdf",
        label: "Template with SO",
      },
      {
        id: 2,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/drifting-goals-summary-so.pdf",
        label: "Summary with SO",
      },
      {
        id: 3,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/drifting-goals-template-%2B-.pdf",
        label: "Template with +-",
      },
      {
        id: 4,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/drifting-goals-summary-%2B-.pdf",
        label: "Summary with +-",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource13.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-t",
      "lg-se",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-students",
      "for-teacahers",
      "for-leaders",
      "h-la",
      "h-sa",
      "h-td",
      "h-cif",
      "t-cl",
      "a-dg",
    ],
  },
  {
    groups: ["template"],
    path: "iceberg-leverage-learning",
    name: "Iceberg: Identifying Leverage and Learning",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "Iceberg template with bubbles for learners to fill in at each level.",
    audience: "All learners",
    concepts: "Behavior-over-time graphs, ladder of inference",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/iceberg-leverage-learning.pdf",
        label: "Identifying Leverage and Learning",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource14.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-t",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "t-ice",
      "lg-ec",
      "lg-pe",
      "lg-se",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-students",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["template"],
    path: "escalation",
    name: "Archetype: Escalation",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content: "Template and summary for the Escalation Archetype",
    audience: "All learners",
    concepts:
      "Behavior-over-time graphs, change over time, successive approximation, short-term, long-term, unintended consequences, changes perspectives to increase understanding, causal loops",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/escalation-template-so.pdf",
        label: "Template with SO",
      },
      {
        id: 2,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/escalation-summary-so.pdf",
        label: "Summary with SO",
      },
      {
        id: 3,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/escalation-template-%2B-.pdf",
        label: "Template with +-",
      },
      {
        id: 4,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/escalation-summary-%2B-.pdf",
        label: "Summary with +-",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource15.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-t",
      "lg-pe",
      "lg-se",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-students",
      "for-teacahers",
      "for-leaders",
      "t-botg",
      "h-cot",
      "h-sa",
      "h-cc",
      "h-cp",
      "t-cl",
      "a-e",
    ],
  },
  {
    groups: ["template"],
    path: "fixes-that-backfire",
    name: "Archetype: Fixes that Backfire",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content: "Template and summary for the Fixes that Backfire Archetype",
    audience: "All learners",
    concepts:
      "Causal loops, long-term, short-term and unintended consequences, changes perspectives",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/fixes-that-backfire-template-so.pdf",
        label: "Template with SO",
      },
      {
        id: 2,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/fixes-that-backfire-summary-so.pdf",
        label: "Summary with SO",
      },
      {
        id: 3,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/fixes-that-backfire-template-%2B-.pdf",
        label: "Template with +-",
      },
      {
        id: 4,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/fixes-that-backfire-summary-%2B-.pdf",
        label: "Summary with +-",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource16.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-t",
      "lg-se",
      "l-pe",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-students",
      "for-teacahers",
      "for-leaders",
      "a-ftb",
      "t-cl",
      "h-cc",
      "h-cp",
    ],
  },
  {
    groups: ["template"],
    path: "limits-to-success",
    name: "Archetype: Limits to Growth/Success",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content: "Template and summary for the Limits to Success/Growth Archetype",
    audience: "All learners",
    concepts: "Causal loops, successive approximation",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/limits-to-growth-template-so.pdf",
        label: "Template with SO",
      },
      {
        id: 2,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/limits-to-growth-summary-so.pdf",
        label: "Summary with SO",
      },
      {
        id: 3,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/limits-to-growth-template-%2B-.pdf",
        label: "Template with +-",
      },
      {
        id: 4,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/limits-to-growth-summary-%2B-.pdf",
        label: "Summary with +-",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource17.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-t",
      "lg-se",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-students",
      "for-teacahers",
      "for-leaders",
      "a-ltgs",
      "h-cl",
      "h-sa",
    ],
  },
  {
    groups: ["template"],
    path: "success-to-the-successful",
    name: "Archetype: Success to the Successful",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content: "Template and summary for the Success to the Successful Archetype",
    audience: "All learners",
    concepts: "Causal loops, structure generates behavior, mental models",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/success-to-the-successful-template-so.pdf",
        label: "Template with SO",
      },
      {
        id: 2,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/success-to-the-successful-summary-so.pdf",
        label: "Summary with SO",
      },
      {
        id: 3,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/success-to-the-successful-template-%2B-.pdf",
        label: "Template with +-",
      },
      {
        id: 4,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/success-to-the-successful-summary-%2B-.pdf",
        label: "Summary with +-",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource18.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-t",
      "lg-se",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-students",
      "for-teacahers",
      "for-leaders",
      "a-stts",
      "t-cl",
      "h-sgb",
      "h-mm",
    ],
  },
  {
    groups: ["template"],
    path: "repression-and-revolution",
    name: "Archetype: Repression and Revolution",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content: "Template and summary for the Repression and Revolution Archetype",
    audience: "All learners",
    concepts:
      "Causal loops; short-term, long-term and unintended consequences; changes perspectives; mental models",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/repression-and-revolution-template-so.pdf",
        label: "Template with SO",
      },
      {
        id: 2,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/repression-and-revolution-summary-so.pdf",
        label: "Summary with SO",
      },
      {
        id: 3,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/repression-and-revolution-template-%2B-.pdf",
        label: "Template with +-",
      },
      {
        id: 4,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/repression-and-revolution-summary-%2B-.pdf",
        label: "Summary with +-",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource19.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-t",
      "lg-se",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-students",
      "for-teacahers",
      "for-leaders",
      "a-rr",
      "t-cl",
      "h-cc",
      "h-cp",
      "h-mm",
    ],
  },
  {
    groups: ["template"],
    path: "shifting-the-burden",
    name: "Archetype: Shifting the Burden",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content: "Template and summary for the Shifting the Burden Archetype",
    audience: "All learners",
    concepts:
      "Causal loops, changes perspectives to increase understanding, considers an issue fully",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/shifting-the-burden-template-so.pdf",
        label: "Template with SO",
      },
      {
        id: 2,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/shifting-the-burden-summary-so.pdf",
        label: "Summary with SO",
      },
      {
        id: 3,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/shifting-the-burden-template-%2B-.pdf",
        label: "Template with +-",
      },
      {
        id: 4,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/shifting-the-burden-summary-%2B-.pdf",
        label: "Summary with +-",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource20.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-t",
      "lg-se",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-students",
      "for-teacahers",
      "for-leaders",
      "a-stb",
      "t-cl",
      "h-cp",
      "h-cif",
    ],
  },
  // {
  //   groups: ["template"],
  //   path: "story-structure",
  //   name: "Archetype: Story Structure",
  //   icons: [
  //     {
  //       id: 1,
  //       name: "templates-icon",
  //       source: "/images/resources/shared/templates-icon.svg",
  //     },
  //   ],
  //   content: "Template and summary for the Story Structure Archetype",
  //   audience: "All learners",
  //   concepts: "Causal loops",
  //   downloads: [
  //     {
  //       id: 1,
  //       url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/story-structure-template-so.pdf",
  //       label: "Template with SO",
  //     },
  //     {
  //       id: 2,
  //       url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/story-structure-summary-so.pdf",
  //       label: "Summary with SO",
  //     },
  //     {
  //       id: 3,
  //       url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/story-structure-template-%2B-.pdf",
  //       label: "Template with +-",
  //     },
  //     {
  //       id: 4,
  //       url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/story-structure-summary-%2B-.pdf",
  //       label: "Summary with +-",
  //     },
  //   ],
  //   sections: [
  //     {
  //       path: "downloads",
  //       component: Resource21.Section01,
  //     },
  //   ],
  //   contentTags: [],
  // },
  {
    groups: ["template"],
    path: "tragedy-of-the-commons",
    name: "Archetype: Tragedy of the Commons",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content: "Template and summary for the Tragedy of the Commons Archetype",
    audience: "All learners",
    concepts:
      "Big picture, changes perspectives to increase understanding, accumulations, circular nature, causal loops",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/tragedy-of-the-commons-template-so.pdf",
        label: "Template with SO",
      },
      {
        id: 2,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/tragedy-of-the-commons-summary-so.pdf",
        label: "Summary with SO",
      },
      {
        id: 3,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/tragedy-of-the-commons-template-%2B-.pdf",
        label: "Template with +-",
      },
      {
        id: 4,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/tragedy-of-the-commons-summary-%2B-.pdf",
        label: "Summary with +-",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource22.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-t",
      "lg-pe",
      "lg-se",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-students",
      "for-teacahers",
      "for-leaders",
      "a-totc",
      "h-bp",
      "h-cp",
      "h-a",
      "h-cn",
      "t-cl",
    ],
  },
  {
    groups: ["template"],
    path: "botg-with-questions",
    name: "Behavior-Over-Time Graphs: BOTGs with Questions",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "The worksheet provides 4 blank BOTGs and questions to consider before and after creating a BOTG.",
    audience: "All learners",
    concepts: "Behavior-over-time graphs",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/botg-with-questions.pdf",
        label: "BOTGs with Questions",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource23.Section01,
      },
    ],
    contentTags: [
      "c-t",
      "c-fg",
      "h-cot",
      "h-sta",
      "t-botg",
      "lg-ec",
      "lg-pe",
      "lg-se",
      "lg-ae",
      "lg-pl",
      "l-en",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["template"],
    path: "connection-circle-botgs",
    name: "Behavior-Over-Time Graphs: Connection Circle with BOTGs",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "Depict key changing elements of a system on BOTG templates and then draw arrows showing how the elements cause changes in each other throughout the circle.",
    audience: "All learners",
    concepts: "Connection Cicle, Behavior-over-time graphs",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/connection-circle-botgs.pdf",
        label: "Connection Circle with BOTGs",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource24.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-t",
      "t-cc",
      "t-botg",
      "h-cot",
      "h-mc",
      "lg-se",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["template"],
    path: "iceberg-3-dimensional",
    name: "Iceberg: 3D Model Template",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "Use this template to cut and fold the iceberg visual into a standing three-dimensional, tactile representation of an iceberg, with the mental models level “hidden” inside the structure, as mental models often are hidden.",
    audience: "All learners",
    concepts: "Iceberg",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/3D-berg-bw.pdf",
        label: "Iceberg: 3D Model Template (B&W)",
      },
      {
        id: 2,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/3D-berg-color.pdf",
        label: "Iceberg: 3D Model Template (Color)",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource25.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-t",
      "t-ice",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-pe",
      "lg-se",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-teachers",
      "for-leaders",
      "for-students",
    ],
  },
  {
    groups: ["template"],
    path: "iceberg-current-reality",
    name: "Iceberg: Current Reality and Desired Results",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "Iceberg template that helps users get closer to desired outcomes by assessing the current reality they are experiencing versus desired results.",
    audience: "All learners",
    concepts: "Iceberg",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/iceberg-current-reality.pdf",
        label: "Iceberg Template: Current Reality and Desired Results",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource26.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-t",
      "t-ice",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-pe",
      "lg-se",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-teachers",
      "for-leaders",
      "for-students",
    ],
  },
  {
    groups: ["template"],
    path: "ladder-with-questions",
    name: "Ladder of Inference: Three Rung Ladder with Simplified Questions",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "Questions guide younger thinkers to focus on what they notice through all of their senses, what they think and ultimately what they do.",
    audience: "All learners",
    concepts: "Ladder of Inference",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/ladder-with-questions.pdf",
        label: "Three Rung Ladder with Simplified Questions",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource27.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-t",
      "t-loi",
      "h-mm",
      "h-cif",
      "lg-ec",
      "lg-pe",
      "for-teachers",
    ],
  },
  {
    groups: ["template"],
    path: "ladder-worksheet",
    name: "Ladder of Inference: Worksheet",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "This worksheet is designed with 6 spaces for the user’s thoughts while considering implications of the Ladder of Inference and the Habits of a Systems Thinker.",
    audience: "All learners",
    concepts: "Ladder of Inference",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/ladder-worksheet.pdf",
        label: "Ladder of Inference: Worksheet",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource29.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-t",
      "h-mm",
      "h-cif",
      "h-cn",
      "t-loi",
      "lg-se",
      "lg-he",
      "lg-pl",
      "lg-ae",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["template"],
    path: "ladder-notice-act",
    name: "Ladder of Inference: Three Rung Ladder with Notice, Believe, Act",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "This version of the ladder is great for thinkers of any age to debrief a common experience by focusing on what they notice, what they believe and how those beliefs lead to action.",
    audience: "Young learners",
    concepts: "Ladder of Inference",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/ladder-notice-act.pdf",
        label: "Three Rung Ladder with Notice, Believe, Act",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource28.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "t-c",
      "h-mm",
      "h-cif",
      "t-loi",
      "lg-ec",
      "lg-pe",
      "for-teachers",
    ],
  },
  {
    groups: ["webinar"],
    path: "borton-boys-video",
    name: "Borton Boys Video",
    icons: [
      // {
      //   id: 1,
      //   name: "webinar-icon",
      //   source: "/images/resources/shared/webinar-icon.svg",
      // },
    ],
    content:
      "Watch as three first grade students share how a causal loop helped them understand and find solutions to a problem they were having on the playground.",
    audience: "All learners",
    concepts: "Causal loops",
    sections: [
      {
        path: "view",
        component: Resource30.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-v",
      "t-cl",
      "h-la",
      "h-cot",
      "h-cn",
      "lg-pe",
      "lg-se",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-teachers",
      "for-students",
      "for-leaders",
    ],
  },
  {
    groups: ["webinar"],
    path: "middle-school-students",
    name: "Middle School Students Interview",
    icons: [
      // {
      //   id: 1,
      //   name: "webinar-icon",
      //   source: "/images/resources/shared/webinar-icon.svg",
      // },
    ],
    content:
      "Middle school students describe their favorite Habit of a Systems Thinker and explain why they chose it.",
    audience: "All learners",
    concepts: "All Habits of a Systems Thinker",
    sections: [
      {
        path: "view",
        component: Resource31.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-v",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-se",
      "lg-pl",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["template"],
    path: "habits-reflection-sheet",
    name: "Habits of a Systems Thinker: Reflection Sheet",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content: "An assessment using the Habits of a Systems Thinker.",
    audience: "All learners",
    concepts:
      "All the Habits of a Systems Thinker and all systems thinking tools",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/habits-reflection-sheet.pdf",
        label: "Habits: Reflection Sheet",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource32.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-t",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-ec",
      "lg-pe",
      "lg-pl",
      "for-teachers",
      "for-leaders",
      "t-cl",
      "t-botg",
    ],
  },
  {
    groups: ["template"],
    path: "habits-single-page",
    name: "Habits of a Systems Thinker: Single Page Version",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content: "A single-page version of the Habits of a Systems Thinker.",
    audience: "All learners",
    concepts:
      "All the Habits of a Systems Thinker and all systems thinking tools",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/habits-single-page-2020.pdf",
        label: "Habits: Single Page Version",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource33.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-t",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-se",
      "lg-pe",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-students",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["template"],
    path: "iceberg-with-graphics",
    name: "Iceberg: Template with Graphics and Questions",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "The graphics and questions included throughout the iceberg visual promote deeper understanding as one searches for leverage in a system.",
    audience: "All learners",
    concepts: "Iceberg",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/iceberg-graphics-questions.pdf",
        label: "Template with Graphics and Questions",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource34.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-t",
      "t-ice",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-se",
      "lg-pe",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-students",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["template"],
    path: "ladder-with-graphics",
    name: "Ladder of Inference: Three Rung Ladder with Habits Graphics",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "Visual version of the ladder helps users make connections between the Ladder of Inference and the Habits of a Systems Thinker.",
    audience: "Young learners",
    concepts: "Ladder of Inference",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/ladder-with-graphics.pdf",
        label: "Three Rung Ladder with Habits Graphics",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource35.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-t",
      "t-loi",
      "h-mm",
      "h-cif",
      "h-cn",
      "lg-ec",
      "lg-pe",
      "for-teachers",
    ],
  },
  {
    groups: ["lesson"],
    path: "water-manager",
    name: "Water Manager Game",
    icons: [
      {
        id: 2,
        name: "primary-icon",
        source: "/images/resources/shared/primary-icon.svg",
      },
    ],
    content:
      "This lesson is designed to follow up a study of Arizona’s water resources, including the Central Arizona Project, Salt River Project, and the systems of dams on Arizona’s rivers. (Lesson could be adjusted to work with water resources in other geographic regions.)",
    audience: "Grades 3 - 4",
    concepts:
      "Habits of a Systems Thinker: Seeks to understand the big picture, Observes how elements within systems change over time, Pays attention to accumulations and their rates of change , Checks results and changes actions if needed: “Successive Approximation”; Tools: Behavior-over-time graph, Stock-flow map",
    sections: [
      {
        path: "procedure",
        component: Resource36.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-lp",
      "h-bp",
      "h-cot",
      "h-a",
      "h-sa",
      "t-botg",
      "t-sfm",
      "lg-pe",
      "for-teachers",
    ],
  },
  {
    groups: ["lesson"],
    path: "ladybugs-and-aphids",
    name: "Ladybugs and Aphids Game",
    icons: [
      {
        id: 2,
        name: "primary-icon",
        source: "/images/resources/shared/primary-icon.svg",
      },
    ],
    content:
      "This game gives students an opportunity to explore predator-prey relationships and see how a balance between the two may or may not be achieved.",
    audience: "Grades 1-3",
    concepts: "Behavior-over-time-graphs and connection circles",
    sections: [
      {
        path: "procedure",
        component: Resource37.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-lp",
      "h-bp",
      "h-cn",
      "h-mc",
      "h-cc",
      "t-botg",
      "t-cl",
      "lg-pe",
      "for-teachers",
    ],
  },
  {
    groups: ["lesson"],
    path: "curiosity",
    name: "Did Curiosity Kill the Cat?",
    icons: [
      {
        id: 2,
        name: "secondary-icon",
        source: "/images/resources/shared/secondary-icon.svg",
      },
    ],
    content:
      "The purpose of this lesson plan is to analyze curiosity as part of an interacting system using connection circles and causal loops (could also be done using a stock-flow map).",
    audience: "Middle school students through adults",
    concepts:
      "Habits of a Systems Thinker:  Seeks to understand the big picture, Identifies the circular nature of complex cause and effect relationships, Makes meaningful connections within and between systems, Uses understanding of system structure to identify possible leverage actions; Tools: Connection circles, Causal loop diagrams",
    sections: [
      {
        path: "procedure",
        component: Resource38.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-ar",
      "c-lp",
      "h-bp",
      "h-cn",
      "h-mc",
      "h-la",
      "t-cc",
      "t-cl",
      "lg-pe",
      "lg-se",
      "lg-he",
      "lg-ae",
      "for-teachers",
    ],
  },
  {
    groups: ["article"],
    path: "structuring-schools",
    name: "Structuring Schools for Success",
    icons: [
      {
        id: 1,
        name: "aricles-icon",
        source: "/images/resources/shared/articles-icon.svg",
      },
    ],
    content:
      "This resource offers the steps, methods, and strategies a team of professional educators successfully used in one school to develop Total Quality Education (TQE). The key strategies for accomplishing this success are spelled out in detail.",
    audience: "All learners, particularly educators",
    concepts: "All Habits of a Systems Thinker and all systems thinking tools",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/structuring-schools-for-success.pdf",
        label: "Structuring Schools for Success Article",
      },
    ],
    sections: [
      {
        path: "article",
        component: Resource39.Section01,
      },
    ],
    contentTags: ["l-en", "c-ar", "for-teachers"],
  },
  {
    groups: ["article"],
    path: "leading-change",
    name: "Leading Change for 21st Century Schools",
    icons: [
      {
        id: 1,
        name: "aricles-icon",
        source: "/images/resources/shared/articles-icon.svg",
      },
    ],
    content:
      "Learn how the growing complexity and challenges facing today’s leaders make a systems thinking approach to creating change more important now than ever.",
    audience: "All learners",
    concepts: "All Habits of a Systems Thinker and all systems thinking tools",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/leading-change-for-21st-centrury-schools.pdf",
        label: "Leading Change Article",
      },
    ],
    sections: [
      {
        path: "article",
        component: Resource40.Section01,
      },
    ],
    contentTags: ["l-en", "c-ar", "for-teachers", "for-leaders"],
  },
  {
    groups: ["article"],
    path: "developing-capacity",
    name: "Developing Systems Thinking Capacity",
    icons: [
      {
        id: 1,
        name: "aricles-icon",
        source: "/images/resources/shared/articles-icon.svg",
      },
    ],
    content:
      "This piece makes the case for why building a systems thinking capacity in learners of all ages is a worthy investment.",
    audience: "All learners",
    concepts: "All Habits of a Systems Thinker and all systems thinking tools",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/developing-st-capacity.pdf",
        label: "Developing Systems Thinking Capacity",
      },
    ],
    sections: [
      {
        path: "article",
        component: Resource41.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-ar",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-ec",
      "lg-pe",
      "lg-se",
      "lg-ae",
      "lg-he",
      "lg-pl",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["assessment"],
    path: "st-rubrics",
    name: "Systems Thinking Rubrics",
    icons: [
      {
        id: 1,
        name: "assessments-icon",
        source: "/images/resources/shared/assessments-icon.svg",
      },
    ],
    content:
      "Rubrics designed by the Catalina Foothills School District to assess and support the integration of systems thinking into units and lessons.",
    audience: "Grades K-12",
    concepts:
      "Change Over Time, Interdependencies, Consequences, System as Cause, Leverage Actions, Big Picture, Self-Regulation and Reflection",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/st-rubric-gk-2.pdf",
        label: "Grades K-2",
      },
      {
        id: 2,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/st-rubric-g3-5.pdf",
        label: "Grades 3-5",
      },
      {
        id: 3,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/st-rubric-g6-8.pdf",
        label: "Grades 6-8",
      },
      {
        id: 4,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/st-rubric-g9-12.pdf",
        label: "Grades 9-12",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource42.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-as",
      "lg-pe",
      "lg-se",
      "for-teachers",
      "h-cot",
      "h-cc",
      "h-cn",
      "h-la",
      "h-sgb",
      "h-bp",
      "h-cif",
    ],
  },
  {
    groups: ["lesson"],
    path: "books-with-st",
    name: "Books with Systems Thinking Connections",
    icons: [
      {
        id: 1,
        name: "primary-icon",
        source: "/images/resources/shared/primary-icon.svg",
      },
      {
        id: 2,
        name: "secondary-icon",
        source: "/images/resources/shared/secondary-icon.svg",
      },
    ],
    content:
      "A list of children's literature and their connections to systems thinking concepts.",
    audience: "Educators to use with students PreK-Elementary",
    concepts:
      "Most Habits of a Systems Thinker and most systems thinking tools",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/books-with-st-connections.pdf",
        label: "Books with ST Connections",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource43.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-ar",
      "c-lp",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "t-botg",
      "t-loi",
      "t-sfm",
      "t-cc",
      "t-cl",
      "t-ice",
      "a-ftb",
      "a-totc",
      "a-e",
      "lg-ec",
      "lg-pe",
      "for-teachers",
      "for-parents",
    ],
  },
  {
    groups: ["lesson"],
    path: "character-comparison",
    name: "Character Comparison Using the Ladder of Inference",
    icons: [
      {
        id: 1,
        name: "primary-icon",
        source: "/images/resources/shared/primary-icon.svg",
      },
    ],
    content:
      "After reading two different versions of “Goldilocks and the Three Bears,” students will create two ladders of inference to analyze the characters’ actions.",
    audience: "Educators to use with students PreK-Elementary",
    concepts:
      "Ladder of Inference, Big Picture, Makes meaningful connections within and between systems, Changes perspectives to increase understanding",
    sections: [
      {
        path: "procedure",
        component: Resource44.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-lp",
      "h-bp",
      "h-mc",
      "h-cp",
      "t-loi",
      "lg-pe",
      "lg-ec",
      "for-teachers",
      "for-parents",
    ],
  },
  {
    groups: ["guide"],
    path: "sense-of-belonging",
    name: "Developing a Sense of Belonging",
    icons: [
      {
        id: 1,
        name: "facilitation-icon",
        source: "/images/resources/shared/facilitation-icon.svg",
      },
    ],
    content:
      "The purpose of this guide is to facilitate the examination of a system and how we can increase students’ sense of belonging in order to increase effectiveness as an institution.",
    audience:
      "School staff members (This was done at a middle school, but it could work at any level.)",
    concepts:
      "Habits of a Systems Thinker: Seeks to understand the big picture, Observes how elements within systems change over time, Recognizes that a system’s structure generates its behavior, Identifies the circular nature of complex cause and effect relationships, Makes meaningful connections within and between systems, Considers an issue fully and resists the urge to come to a quick conclusion, Uses understanding of system structure to identify possible leverage actions; Tools: Stock-flow maps, Behavior-over-time graphs",
    sections: [
      {
        path: "procedure",
        component: Resource45.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-lp",
      "c-fg",
      "h-bp",
      "h-cot",
      "h-sgb",
      "h-cn",
      "h-mc",
      "h-cif",
      "h-sgb",
      "t-botg",
      "t-sfm",
      "lg-se",
      "lg-ae",
      "for-teachers",
    ],
  },
  {
    groups: ["lesson"],
    path: "to-kill-a-mockingbird",
    name: "To Kill a Mockingbird: A Study of Culture, Perspective, Stereotypes and Systems (Unit Plan Template)  ",
    icons: [
      {
        id: 2,
        name: "secondary-icon",
        source: "/images/resources/shared/secondary-icon.svg",
      },
    ],
    content:
      "This unit plan template will guide secondary teachers in creating a meaningful learning experience for students using many elements of systems thinking to analyze important themes of the book, To Kill a Mockingbird.",
    audience: "Secondary students (primarily in an English Literature setting)",
    concepts:
      "All Habits of a Systems Thinker and tools, specifically the ladder of inference, iceberg, connection circle and causal loops",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/mockingbird-unit-plan.pdf",
        label: "Unit Plan Template",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource46.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-lp",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "t-loi",
      "t-ice",
      "t-cc",
      "t-cl",
      "lg-se",
      "lg-ae",
      "for-teachers",
    ],
  },
  {
    groups: ["lesson"],
    path: "hey-little-ant",
    name: "Hey, Little Ant",
    icons: [
      {
        id: 2,
        name: "primary-icon",
        source: "/images/resources/shared/primary-icon.svg",
      },
    ],
    content:
      'Students will compare characters using the Ladder of Inference through the reading of "Hey, Little Ant" by Phillip and Hannah Hoose.',
    audience: "Kindergarten - Grade 3",
    concepts:
      "Habits of a Systems Thinker: Seeks to understand the big picture, Changes perspectives to increase understanding, Considers short-term, long-term, and unintended consequences of actions; Tools: Ladder of Inference",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/character-comparison-ladder.pdf",
        label: "Ladder Template",
      },
    ],
    sections: [
      {
        path: "procedure",
        component: Resource47.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-lp",
      "h-bp",
      "h-cp",
      "h-cc",
      "t-loi",
      "lg-ec",
      "lg-pe",
      "for-teachers",
      "for-parents",
    ],
  },
  {
    groups: ["guide"],
    path: "mirror-mirror-star",
    name: "Mirror, Mirror and the Star",
    icons: [
      {
        id: 1,
        name: "facilitation-icon",
        source: "/images/resources/shared/facilitation-icon.svg",
      },
    ],
    content:
      "The purpose of this activity is to help others understand the complexity and oftentimes difficulty that occurs when attempting to change perspectives while completing an action.",
    audience: "Secondary students – Adults in any type of system",
    concepts:
      "Changes Perspectives, Successive Approximation, Leverage, Mental Models",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/mirror-mirror-star-handout.pdf",
        label: "Star Template",
      },
    ],
    sections: [
      {
        path: "procedure",
        component: Resource48.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-fg",
      "h-cp",
      "h-sa",
      "h-la",
      "h-mm",
      "lg-se",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["lesson"],
    path: "maze-craze",
    name: "Maze Craze",
    icons: [
      {
        id: 1,
        name: "primary-icon",
        source: "/images/resources/shared/primary-icon.svg",
      },
      {
        id: 2,
        name: "secondary-icon",
        source: "/images/resources/shared/secondary-icon.svg",
      },
      {
        id: 3,
        name: "facilitation-icon",
        source: "/images/resources/shared/facilitation-icon.svg",
      },
    ],
    content:
      "In this lesson, participants will work through the engineering design process focusing on the Next Generation Science Standards (NGSS) that deal with solving problems through testing possible solutions.",
    audience:
      "Upper Elementary – Secondary students and teams from Organizations (with modifications for time)",
    concepts: "All Habits of a Systems Thinker, Causal loops",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/maze-craze-handout.pdf",
        label: "Maze Craze Handout",
      },
    ],
    sections: [
      {
        path: "intro",
        component: Resource49.Section01,
      },
      {
        path: "lesson-structure",
        component: Resource49.Section02,
      },
    ],
    contentTags: [
      "l-en",
      "c-lp",
      "h-bp",
      "h-sa",
      "h-cp",
      "h-cif",
      "h-mc",
      "t-cl",
      "lg-pe",
      "lg-se",
      "lg-ae",
      "for-teachers",
    ],
  },
  {
    groups: ["webinar"],
    path: "characteristics-of-a-system",
    name: "Characteristics of a System: An Open Door to Integrating Systems Thinking",
    icons: [
      // {
      //   id: 1,
      //   name: "webinar-icon",
      //   source: "/images/resources/shared/webinar-icon.svg",
      // },
    ],
    content:
      "How are the characteristics of a system like a math problem? In this video, learn the characteristics of a system and how this understanding can be used to integrate systems thinking  into your work with groups of learners in a variety of settings.",
    audience: "All learners, specifically PreK-Secondary educators",
    concepts: "System structure, Meaningful connections",
    sections: [
      {
        path: "view",
        component: Resource50.Section01,
      },
    ],
    contentTags: ["l-en", "c-v", "h-mc", "lg-pe", "lg-se", "for-teachers"],
  },
  {
    groups: ["lesson"],
    path: "word-up",
    name: "Word Up",
    icons: [
      {
        id: 1,
        name: "primary-icon",
        source: "/images/resources/shared/primary-icon.svg",
      },
    ],
    content:
      "This lesson can be used to introduce vocabulary words and/or enrich students’ vocabulary to show appropriate situations to use vocabulary. Extensions include writing prompts to have students utilize the words.",
    audience: "Grades 3-5, but could be adapted to younger or older grades",
    concepts: "Behavior-over-time graphs (BOTG) ",
    sections: [
      {
        path: "lesson-structure",
        component: Resource51.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-lp",
      "h-cp",
      "h-cot",
      "h-mm",
      "t-botg",
      "t-sfm",
      "lg-pe",
      "for-teachers",
    ],
  },
  {
    groups: ["webinar"],
    path: "st-math-discourse",
    name: "Systems Thinking and Math Discourse Webinar",
    icons: [
      {
        id: 1,
        name: "webinar-icon",
        source: "/images/resources/shared/webinar-icon.svg",
      },
    ],
    content:
      "In this webinar, hosted by Danielle Robinson, Math Interventionist at Milwaukee Public Schools, learn how to use systems thinking Habits and tools to help students build and use discourse in mathematics to develop a shared understanding of mathematical concepts and ideas. Webinar recorded in 2018.",
    audience: "Educators with a focus in mathematics",
    concepts: "BOTG, Stock-Flow, Causal loops, all Habits of a Systems Thinker",
    sections: [
      {
        path: "view",
        component: Resource52.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-w",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "t-botg",
      "t-cl",
      "t-sfm",
      "lg-pe",
      "for-teachers",
    ],
  },
  {
    groups: ["lesson"],
    path: "caring-classroom",
    name: "Caring Classroom",
    icons: [
      {
        id: 1,
        name: "primary-icon",
        source: "/images/resources/shared/primary-icon.svg",
      },
    ],
    content:
      "Students will listen to several books read aloud (i.e. read alouds) that they will use to create behavior-over-time graphs. The behavior-over-time graphs will reinforce caring classroom behavior. While creating these graphs, students will increase vocabulary knowledge and usage and will be able to plot a range of specific feelings/behaviors of focus on each graph. After creating several behavior-over-time graphs, the class will create a correlating stock-flow map. The unit ends with fun extension activities: authoring a book entitled “What is a Good Friend?” and making Friendship Mix.",
    audience:
      "Kindergarten class of 5 and 6-year-olds (This lesson could be used in any primary grade with content and time modifications.)",
    concepts:
      "Behavior-over-time graph, Stock-flow map, and 8 of the Habits of a Systems Thinker",
    sections: [
      {
        path: "details",
        component: Resource53.Section01,
      },
      {
        path: "lesson-structure",
        component: Resource53.Section02,
      },
    ],
    contentTags: [
      "l-en",
      "c-lp",
      "h-sgb",
      "h-mc",
      "h-bp",
      "h-cif",
      "h-cn",
      "h-cp",
      "h-cc",
      "h-sa",
      "t-botg",
      "t-sfm",
      "lg-ec",
      "lg-pe",
      "for-teachers",
    ],
  },
  {
    groups: ["article"],
    path: "classroom-management",
    name: "The Big Picture of Classroom Management",
    icons: [
      {
        id: 1,
        name: "aricles-icon",
        source: "/images/resources/shared/articles-icon.svg",
      },
    ],
    content:
      "In this piece, written by Mary Quinnan of the Waters Center, explore effective classroom management as it relates to the Habits of a Systems Thinker.",
    audience: "Educators",
    concepts: "All Habits of a Systems Thinker",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/classroom-management.pdf",
        label: "The Big Picture of Classroom Management",
      },
    ],
    sections: [
      {
        path: "article",
        component: Resource54.Section01,
      },
    ],
    contentTags: ["l-en", "c-ar", "for-teachers", "for-leaders", "h-bp"],
  },
  {
    groups: ["template"],
    path: "habits-single-page-italian",
    name: "Abitudini di un Pensatore Sistemico: Versione a pagina singola, Tradotto in italiano",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "Our single-page version of the Habits of a Systems Thinker, translated into Italian.",
    audience: "All learners",
    concepts:
      "All the Habits of a Systems Thinker and all systems thinking tools",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/onepage-habits-italian.pdf",
        label: "Abitudini di un Pensatore Sistemico: Pagina singola, Italiano",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource55.Section01,
      },
    ],
    contentTags: [
      "l-it",
      "c-t",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-se",
      "lg-pe",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-students",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["article"],
    path: "st-in-schools",
    name: "The Impact of the Systems Thinking in Schools Project: 20 years of Research, Development and Dissemination",
    icons: [
      {
        id: 1,
        name: "aricles-icon",
        source: "/images/resources/shared/articles-icon.svg",
      },
    ],
    content:
      "This paper describes the impact of the Systems Thinking in Schools project on 21st century student learning and achievement.",
    audience: "All learners",
    concepts: "All Habits of a Systems Thinker",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/st-in-schools.pdf",
        label:
          "The Impact of the Systems Thinking in Schools Project: 20 years of Research, Development and Dissemination",
      },
    ],
    sections: [
      {
        path: "article",
        component: Resource56.Section01,
      },
    ],
    contentTags: ["l-en", "c-ar", "for-teachers"],
  },
  {
    groups: ["webinar"],
    path: "ts-seasons-salmon-storms",
    name: "Teacher Studio Webinar Recording: Seasons, Salmon and Storms: Integrating Systems Thinking with Science Standards",
    icons: [
      {
        id: 1,
        name: "webinar-icon",
        source: "/images/resources/shared/webinar-icon.svg",
      },
    ],
    content:
      "This Teacher Studio was recorded on Nov. 7, 2020 with special guest Maria Simpson, science specialist, from Winston Salem Forsythe County School District in North Carolina, who shares some of her best systems thinking integrated lessons.",
    audience: "Educators",
    concepts: "All",
    sections: [
      {
        path: "view",
        component: Resource57.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-w",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "t-botg",
      "t-cc",
      "t-sfm",
      "lg-ec",
      "lg-pe",
      "for-teachers",
    ],
  },
  {
    groups: ["webinar"],
    path: "os-taking-action",
    name: "Open Studio Webinar Recording: Taking Action: Using Systems Thinking to Tackle Complex Community Challenges",
    icons: [
      {
        id: 1,
        name: "webinar-icon",
        source: "/images/resources/shared/webinar-icon.svg",
      },
    ],
    content:
      "This Open Studio was recorded on Nov. 5, 2020 and facilitated by Mackenzie Pish, Program Manager for the University of Arizona Law School’s Innovation for Justice Program, and Alexandria Sedar, a Peacebuilder specializing in systems thinking, international development and public health.",
    audience: "All learners",
    concepts: "All",
    sections: [
      {
        path: "view",
        component: Resource58.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-w",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-leaders",
      "for-parents",
    ],
  },
  {
    groups: ["article"],
    path: "story-of-rebuild",
    name: "Shifting Mindsets Through Cultural Barriers: The Story of Rebuild",
    icons: [
      {
        id: 1,
        name: "aricles-icon",
        source: "/images/resources/shared/articles-icon.svg",
      },
    ],
    content:
      "Learn about the remarkable systems thinking work being done in Afghanistan by Barbie and Peter Reynolds of Rebuild Consultants.",
    audience: "All learners",
    concepts: "All Habits of a Systems Thinker and all systems thinking tools",
    sections: [
      {
        path: "article",
        component: Resource59.Section01,
      },
    ],
    contentTags: ["l-en", "c-ar", "for-leaders"],
  },
  {
    groups: ["webinar"],
    path: "peggy-gingerich-interview",
    name: "Systems Thinking in School Communities: Peggy Gingerich",
    icons: [
      // {
      //   id: 1,
      //   name: "webinar-icon",
      //   source: "/images/resources/shared/webinar-icon.svg",
      // },
    ],
    content:
      "Hear from Peggy Gingerich,  early childhood teacher, 3 to 5 years, Head Start, Wolfe County, Middle Kentucky, who was a part of the Waters Center “Systems Thinking in School Communities” project, funded by the W.K. Kellogg Foundation.",
    audience: "Administrators and educators, specifically early childhood",
    concepts: "All",
    sections: [
      {
        path: "view",
        component: Resource60.Section01,
      },
    ],
    contentTags: ["l-en", "c-v", "t-botg", "lg-ec", "lg-pe", "for-teachers"],
  },
  {
    groups: ["webinar"],
    path: "chris-holiday-interview",
    name: "Systems Thinking in School Communities: Chris Holiday",
    icons: [
      // {
      //   id: 1,
      //   name: "webinar-icon",
      //   source: "/images/resources/shared/webinar-icon.svg",
      // },
    ],
    content:
      "Hear from Chris Holiday, Disability, Mental Health & Nutrition Manager, Head Start, Middle Kentucky Community Action, who was a part of the Waters Center “Systems Thinking in School Communities” project, funded by the W.K. Kellogg Foundation.",
    audience: "Administrators and educators, specifically early childhood",
    concepts: "All",
    sections: [
      {
        path: "view",
        component: Resource61.Section01,
      },
    ],
    contentTags: ["l-en", "c-v", "lg-ec", "lg-pl", "for-teachers"],
  },
  {
    groups: ["article"],
    path: "st-for-all",
    name: "No Better Time: Systems Thinking for All",
    icons: [
      {
        id: 1,
        name: "aricles-icon",
        source: "/images/resources/shared/articles-icon.svg",
      },
    ],
    content:
      "In this article, written by Waters Center President, Tracy Benson and published in the Winter 2021 edition of Kansas Child Magazine, learn why there is no better time to learn and implement systems thinking for our youngest learners and beyond.",
    audience: "All learners",
    concepts: "All Habits of a Systems Thinker and all systems thinking tools",
    sections: [
      {
        path: "article",
        component: Resource62.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-ar",
      "lg-ec",
      "lg-pe",
      "for-teachers",
      "for-leaders",
      "for-parents",
    ],
  },
  {
    groups: ["article"],
    path: "beyond-virtual",
    name: "Beyond Virtual: A Community of Learners Devoted to the Health System",
    icons: [
      {
        id: 1,
        name: "aricles-icon",
        source: "/images/resources/shared/articles-icon.svg",
      },
    ],
    content:
      "Read a summary of the Health System Forum, presented by the Waters Center for Systems Thinking on Feb. 11, 2021.",
    audience: "All learners",
    concepts: "All Habits of a Systems Thinker and all systems thinking tools",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/beyond-virtual.pdf",
        label:
          "Beyond Virtual: A Community of Learners Devoted to the Health System",
      },
    ],
    sections: [
      {
        path: "article",
        component: Resource63.Section01,
      },
    ],
    contentTags: ["l-en", "c-ar", "lg-ae", "lg-pl", "for-leaders"],
  },
  {
    groups: ["guide"],
    path: "habits-newsletters",
    name: "Using Monthly Newsletters to Explore the Habits",
    icons: [
      {
        id: 1,
        name: "facilitation-icon",
        source: "/images/resources/shared/facilitation-icon.svg",
      },
    ],
    content:
      "These one-page sheets provide questions and activities that teachers, school leaders, and others can use to put the Habits of a Systems Thinker into practice with students and adults.",
    audience: "Adults working in schools, as well as in other workplaces",
    concepts: "Habits of a Systems Thinker",
    sections: [
      {
        path: "view",
        component: Resource64.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-ar",
      "c-t",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cif",
      "h-cc",
      "h-mm",
      "h-sgb",
      "lg-se",
      "lg-ae",
      "lg-pl",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["template"],
    path: "habits-single-page-spanish",
    name: "Hábitos de un Pensador Sistemático: Versión de una sola página, Traducción al español",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "Our single-page version of the Habits of a Systems Thinker, translated into Spanish.",
    audience: "All learners",
    concepts:
      "All the Habits of a Systems Thinker and all systems thinking tools",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/onepage-habits-spanish.pdf",
        label: "Hábitos de un Pensador Sistemático: Sola pagina, Español",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource65.Section01,
      },
    ],
    contentTags: [
      "l-es",
      "c-t",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-se",
      "lg-pe",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-students",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["guide"],
    path: "habits-coaching-questions",
    name: "Questions for Coaching and Collaboration",
    icons: [
      {
        id: 1,
        name: "facilitation-icon",
        source: "/images/resources/shared/facilitation-icon.svg",
      },
    ],
    content:
      "This resource reinforces the importance of thought-provoking questions when in coaching and collaborative settings.",
    audience: "Anyone in a coaching or collaborative setting",
    concepts: "All of the Habits of a Systems Thinker",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/habits-coaching-questions.pdf",
        label: "Questions for Coaching and Collaboration",
      },
    ],
    sections: [
      {
        path: "download",
        component: Resource66.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-t",
      "c-ar",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-he",
      "lg-pl",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["template"],
    path: "habits-single-page-japanese",
    name: "システム思考家の習慣: 単一ページ版, 日本語訳",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "Our single-page version of the Habits of a Systems Thinker, translated into Japanese.",
    audience: "All learners",
    concepts:
      "All the Habits of a Systems Thinker and all systems thinking tools",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/onepage-habits-japanese.pdf",
        label: "システム思考家の習慣: 単一ページ, 日本語",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource67.Section01,
      },
    ],
    contentTags: [
      "l-ja",
      "c-t",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-se",
      "lg-pe",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-students",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["article"],
    path: "jim-waters-tribute",
    name: "Celebrating the Life and Lessons of James L. Waters",
    icons: [
      {
        id: 1,
        name: "aricles-icon",
        source: "/images/resources/shared/articles-icon.svg",
      },
    ],
    content:
      "James “Jim” Waters, founder of Waters Center for Systems Thinking, passed away peacefully on May 17, 2021 at the age of 95. Read about Jim and his legacy.",
    audience: "All learners",
    concepts: "All Habits of a Systems Thinker and all systems thinking tools",
    sections: [
      {
        path: "article",
        component: Resource68.Section01,
      },
    ],
    contentTags: ["l-en", "c-ar", "h-sa", "lg-pl", "for-leaders"],
  },
  {
    groups: ["article"],
    path: "honoring-mary-scheetz",
    name: "Walking the Talk: Honoring Mary Scheetz, a Systems Thinking Leader",
    icons: [
      {
        id: 1,
        name: "aricles-icon",
        source: "/images/resources/shared/articles-icon.svg",
      },
    ],
    content:
      "For twenty four years, Mary Scheetz was the Director of the Waters Foundation, Systems Thinking in Schools (now Waters Center for Systems Thinking). Read about Mary’s legacy in this article commemorating her retirement.",
    audience: "All learners",
    concepts: "All Habits of a Systems Thinker and all systems thinking tools",
    sections: [
      {
        path: "article",
        component: Resource69.Section01,
      },
    ],
    contentTags: ["l-en", "c-ar", "lg-pl", "for-leaders"],
  },
  {
    groups: ["article"],
    path: "interview-with-taylor-davidson",
    name: "Putting Good into the World Through Systems Thinking: An Interview with Taylor Davidson",
    icons: [
      {
        id: 1,
        name: "aricles-icon",
        source: "/images/resources/shared/articles-icon.svg",
      },
    ],
    content:
      "Taylor Davidson, Owner and Broker of Neil Benton Arts & Entertainment, wasintroduced to systems thinking as a middle schooler. Read about how he uses principles of systems thinking in his company’s operations and mission to put good into the world.",
    audience: "All learners",
    concepts: "All Habits of a Systems Thinker and all systems thinking tools",
    sections: [
      {
        path: "article",
        component: Resource70.Section01,
      },
    ],
    contentTags: ["l-en", "c-ar", "lg-pl", "for-leaders"],
  },
  {
    groups: ["webinar"],
    path: "school-leadership-forum-speakers",
    name: "Building Pathways for Systems Thinkers: Reimagining School Leadership Forum, Guest Speaker Videos",
    icons: [
      {
        id: 1,
        name: "webinar-icon",
        source: "/images/resources/shared/webinar-icon.svg",
      },
    ],
    content:
      "The Reimagining School Leadership Forum, held on June 24, 2021, featured several guest speakers from the field. Hear their stories of using systems thinking with students and staff.",
    audience: "All learners",
    concepts: "All Habits of a Systems Thinker and all systems thinking tools",
    sections: [
      {
        path: "view",
        component: Resource71.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-w",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "t-botg",
      "t-loi",
      "t-ice",
      "t-sfm",
      "t-cld",
      "t-cl",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-leaders",
    ],
  },
  {
    groups: ["template"],
    path: "habits-single-page-persian",
    name: "عادات یک متفکر سیستمی:ترجمه فارسی،نسخه تک صفحه ای",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "Our single-page version of the Habits of a Systems Thinker, translated into Persian.",
    audience: "All learners",
    concepts:
      "All the Habits of a Systems Thinker and all systems thinking tools",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/onepage-habits-persian.pdf",
        label: "ادات یک متفکر سیستمی:ترجمه فارسی،نسخه تک صفحه ای",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource72.Section01,
      },
    ],
    contentTags: [
      "l-fa",
      "c-t",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-se",
      "lg-pe",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-students",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["webinar"],
    path: "os-applied-st",
    name: "Open Studio Webinar Recording: A Conversation with David Stroh & Michael Goodman: Applied Systems Thinking",
    icons: [
      {
        id: 1,
        name: "webinar-icon",
        source: "/images/resources/shared/webinar-icon.svg",
      },
    ],
    content:
      "This Open Studio Webinar, which occurred January 6, 2022, was facilitated by David Stroh and Michael Goodman, whose global experiences using systems thinking span more than five decades. Their work includes teaching, facilitating, and consulting in the social, public, and private sectors.",
    audience: "All learners",
    concepts: "All Habits of a Systems Thinker and all systems thinking tools",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/WatersCenter_presentationNotes_AppliedSystemsThinking_Jan2022.pdf",
        label: "Presentation Slides",
      },
    ],
    sections: [
      {
        path: "view",
        component: Resource73.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-w",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "t-cl",
      "lg-he",
      "lg-pl",
      "for-leaders",
    ],
  },
  {
    groups: ["template"],
    path: "habits-single-page-german",
    name: "Gewohnheiten für Systemdenkende: Einzelseitenversion, Deutsche Übersetzung",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "Our single-page version of the Habits of a Systems Thinker, translated into German.",
    audience: "All learners",
    concepts:
      "All the Habits of a Systems Thinker and all systems thinking tools",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/onepage-habits-german.pdf",
        label: "Gewohnheiten für Systemdenkende: Einzelseite, Deutsche",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource74.Section01,
      },
    ],
    contentTags: [
      "l-de",
      "c-t",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-se",
      "lg-pe",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-students",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["template"],
    path: "habits-single-page-portuguese",
    name: "Hábitos de um Pensador Sistêmico: Versão de página única, Tradução para Português",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "Our single-page version of the Habits of a Systems Thinker, translated into Portuguese.",
    audience: "All learners",
    concepts:
      "All the Habits of a Systems Thinker and all systems thinking tools",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/onepage-habits-portuguese.pdf",
        label: "Hábitos de um Pensador Sistêmico: Página única, Português",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource75.Section01,
      },
    ],
    contentTags: [
      "l-pt",
      "c-t",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-se",
      "lg-pe",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-students",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["webinar"],
    path: "os-now-what",
    name: "Open Studio Webinar Recording: Now What? A Call to Action with Alan Ticotsky",
    icons: [
      {
        id: 1,
        name: "webinar-icon",
        source: "/images/resources/shared/webinar-icon.svg",
      },
    ],
    content:
      "This Open Studio Webinar, which occurred June 2, 2022, was facilitated by Alan Ticotsky, who shared some of his work and facilitated dialogue as we considered what action we could take going forward.",
    audience: "All learners",
    concepts: "All Habits of a Systems Thinker and all systems thinking tools",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/webinar-slides/NowWhat-OpenStudio.pptx",
        label: "Presentation Slides",
      },
    ],
    sections: [
      {
        path: "view",
        component: Resource76.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-w",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "t-botg",
      "t-cl",
      "t-ice",
      "t-sfm",
      "a-dg",
      "lg-se",
      "lg-he",
      "lg-ae",
      "for-leaders",
    ],
  },
  {
    groups: ["template"],
    path: "levels-of-commitment",
    name: "Levels of Commitment",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "Use this template to ask, “As a staff, team or department, how committed are we to our vision?” and “Where am I as I consider my level of commitment to the vision?“ Consider printing this template on large paper and providing people with two colors of sticky dots to indicate responses to each question: One colored dot for their indication of staff or large group commitment level and another color for their level as an individual. After all have made level selections, ask, “What does this colored dot data tell us?”",
    audience: "All learners",
    concepts:
      "All the Habits of a Systems Thinker and all systems thinking tools",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/levels-of-commitment.pdf",
        label: "Levels of Commitment Template",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource77.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-t",
      "h-cot",
      "h-cp",
      "h-mm",
      "lg-he",
      "lg-pl",
      "for-leaders",
    ],
  },
  {
    groups: ["template"],
    path: "habits-sort",
    name: "Habits Sort",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "Test your knowledge of the Habits of a Systems Thinker with this interactive activity. Cut out the images from the Habits cards and try to match each one with the text of the Habit. This activity is available in both English and Spanish.",
    audience: "All learners",
    concepts: "All the Habits of a Systems Thinker",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/habits-sort.pdf",
        label: "Habits Sort - English",
      },
      {
        id: 2,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/habits-sort-bwframes.pdf",
        label: "Habits Sort - English (Black & White Frames)",
      },
      {
        id: 3,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/habits-sort-espanol.pdf",
        label: "Habits Sort - Español",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource78.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "l-es",
      "c-t",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-pe",
      "lg-se",
      "for-teachers",
    ],
  },
  {
    groups: ["template"],
    path: "foldable-botg",
    name: "Foldable BOTG Template",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "The foldable behavior-over-time graph is a great way to record the highlights, experiences, or explanations that influence the changes or steady state of a BOTG underneath each section of the graph.",
    audience: "All learners",
    concepts: "Behavior Over Time Graphs",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/foldable-botg-generic.pdf",
        label: "Foldable BOTG Template",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource79.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-t",
      "h-cot",
      "t-botg",
      "lg-pe",
      "lg-se",
      "lg-ae",
      "lg-pl",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["webinar"],
    path: "os-leaders-our-systems-deserve",
    name: "Open Studio Webinar Recording: Developing the Leaders Our Systems Deserve",
    icons: [
      {
        id: 1,
        name: "webinar-icon",
        source: "/images/resources/shared/webinar-icon.svg",
      },
    ],
    content:
      "This Open Studio Webinar, which occurred June 1, 2023, was facilitated by Lihi Rosenthal.",
    audience: "All learners",
    concepts: "All Habits of a Systems Thinker and all systems thinking tools",
    sections: [
      {
        path: "view",
        component: Resource80.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-w",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "t-loi",
      "lg-he",
      "lg-pl",
      "for-leaders",
    ],
  },
  {
    groups: ["webinar"],
    path: "os-planting-the-seeds",
    name: "Open Studio Webinar Recording: Planting the Seeds and Growing Systems Thinking in Your Organization",
    icons: [
      {
        id: 1,
        name: "webinar-icon",
        source: "/images/resources/shared/webinar-icon.svg",
      },
    ],
    content:
      "This Open Studio Webinar, which occurred May 4, 2023, was facilitated by Kelly Nichols, Jackie White, and Victor Yagi.",
    audience: "All learners",
    concepts: "All Habits of a Systems Thinker and all systems thinking tools",
    sections: [
      {
        path: "view",
        component: Resource81.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-w",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-he",
      "lg-pl",
      "for-leaders",
    ],
  },
  {
    groups: ["template"],
    path: "habits-single-page-french",
    name: "Les habitudes d’un Penseur en Systèmes: Version d'une page, Traduction française",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "Our single-page version of the Habits of a Systems Thinker, translated into French.",
    audience: "All learners",
    concepts:
      "All the Habits of a Systems Thinker and all systems thinking tools",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/onepage-habits-french.pdf",
        label: "Les habitudes d’un Penseur en Systèmes: Une page, Français",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource82.Section01,
      },
    ],
    contentTags: [
      "l-fr",
      "c-t",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-se",
      "lg-pe",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-students",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["template"],
    path: "iceberg-scientific-phenomenon",
    name: "Iceberg: Scientific Phenomenon",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "Iceberg template for science teachers to guide students through sensemaking using systems thinking tools and science practices.",
    audience: "All learners",
    concepts:
      "Behavior-over-time graphs, ladder of inference, Habits of a Systems Thinker",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/iceberg-phenomenon.pdf",
        label: "Iceberg: Scientific Phenomenon",
      },
      {
        id: 2,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/iceberg-phenomenon-halves.pdf",
        label: "Iceberg: Scientific Phenomenon, Two Halves",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource83.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-t",
      "t-botg",
      "h-cot",
      "t-loi",
      "t-ice",
      "lg-se",
      "lg-he",
      "lg-ae",
      "for-teachers",
    ],
  },
  {
    groups: ["webinar"],
    path: "os-imperfect-leader",
    name: 'Open Studio Webinar Recording: "An Imperfect Leader: Human-Centered Leadership in (After) Action" with Peter Stiepleman',
    icons: [
      {
        id: 1,
        name: "webinar-icon",
        source: "/images/resources/shared/webinar-icon.svg",
      },
    ],
    content:
      'This Open Studio Webinar, which occurred September 7, 2023, was facilitated by Peter Stiepleman, author of "An Imperfect Leader: Human-Centered Leadership in (After) Action" and host of a weekly podcast with the same name. Peter shares a leadership model that anchors itself in Peter Senge’s words, "What do we want to create together?" and engage participants in some systems thinking scenarios.',
    audience: "All learners",
    concepts: "All Habits of a Systems Thinker and all systems thinking tools",
    sections: [
      {
        path: "view",
        component: Resource84.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-w",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-he",
      "lg-pl",
      "for-leaders",
    ],
  },
  {
    groups: ["template"],
    path: "habits-single-page-dutch",
    name: "Denkgewoonten van een Systeemdenker: Versie met één pagina, Nederlandse vertaling",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "Our single-page version of the Habits of a Systems Thinker, translated into Dutch.",
    audience: "All learners",
    concepts:
      "All the Habits of a Systems Thinker and all systems thinking tools",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/onepage-habits-dutch.pdf",
        label: "Denkgewoonten van een Systeemdenker: Eén pagina, Nederlands",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource85.Section01,
      },
    ],
    contentTags: [
      "l-nl",
      "c-t",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-se",
      "lg-pe",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-students",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["template"],
    path: "habits-single-page-finnish",
    name: "Systeemiajattelijan tavat: Yksisivuinen versio, suomi käännös",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "Our single-page version of the Habits of a Systems Thinker, translated into Finnish.",
    audience: "All learners",
    concepts:
      "All the Habits of a Systems Thinker and all systems thinking tools",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/onepage-habits-finnish.pdf",
        label: "Systeemiajattelijan tavat: yksisivuinen, suomi",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource86.Section01,
      },
    ],
    contentTags: [
      "l-fi",
      "c-t",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-se",
      "lg-pe",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-students",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["webinar"],
    path: "os-peacebuilding-habits",
    name: 'Open Studio Webinar Recording: "Starting the Ripple: Peacebuilding Habits for Systems Change',
    icons: [
      {
        id: 1,
        name: "webinar-icon",
        source: "/images/resources/shared/webinar-icon.svg",
      },
    ],
    content:
      "In this Open Studio Webinar, which occurred February 1, 2024, get to know the complementary habits of peacebuilding and systems thinking with peace practitioners and WCST Advanced Facilitators Laura Hermanns, MSP, and Alexandria Sedar, MSP. Through reflection, application of systems thinking tools, and insights on developing a personal practice, we will explore together how even small actions can ripple outward and create positive change in the world.",
    audience: "All learners",
    concepts: "All Habits of a Systems Thinker and all systems thinking tools",
    sections: [
      {
        path: "view",
        component: Resource87.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-w",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "t-cl",
      "lg-he",
      "lg-pl",
      "lg-se",
      "lg-ae",
      "for-leaders",
    ],
  },
  {
    groups: ["webinar"],
    path: "os-fixes-that-fail",
    name: "Open Studio Webinar Recording: We Don’t Need Another Hero: A Case Study of the Archetype “Fixes that Fail”",
    icons: [
      {
        id: 1,
        name: "webinar-icon",
        source: "/images/resources/shared/webinar-icon.svg",
      },
    ],
    content:
      "In this September 5, 2024 Open Studio Webinar, Kristina and Ken Tyler, who provide business consulting and services through Flow Fiero LLP, will walk you through their experience of using the archetype “Fixes that Fail” to uncover blind spots and entanglements in leadership. They will share how they have utilized systems thinking tools and habits to help businesses grow beyond the vicious cycles holding them back.",
    audience: "All learners",
    concepts: "All Habits of a Systems Thinker and all systems thinking tools",
    downloads: [],
    sections: [
      {
        path: "view",
        component: Resource88.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-w",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "t-ice",
      "lg-he",
      "lg-pl",
      "for-leaders",
      "a-ftb",
    ],
  },
  {
    groups: ["template"],
    path: "double-ladders",
    name: "Ladder of Inference: Double Ladder Templates",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "Double Ladder of Inference template, and a version with data-centered questions.",
    audience: "All lerners",
    concepts: "Ladder of Inference",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/double-ladder.pdf",
        label: "Double Ladder Template",
      },
      {
        id: 2,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/double-ladder-data.pdf",
        label: "Double Ladder with Data Application Template",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource89.Section01,
      },
    ],
    contentTags: [
      "for-students",
      "for-teachers",
      "t-loi",
      "lg-he",
      "lg-ae",
      "l-en",
      "c-t",
    ],
  },
  {
    groups: ["webinar"],
    path: "os-building-a-more-equitable-world-using-st",
    name: "Open Studio Webinar Recording: Building a More Equitable World Using Systems Thinking",
    icons: [
      {
        id: 1,
        name: "webinar-icon",
        source: "/images/resources/shared/webinar-icon.svg",
      },
    ],
    content:
      "In Waters Center's October 2024 Open Studio Webinar, we collectively consider how systems thinking can be used to strengthen our efforts to advance equity. Melanie Houston, Waters Center Systems Thinking Specialist, will share her experience and reflections from real-world applications of systems thinking in equity-promoting efforts. Participants will have an opportunity to apply systems thinking as they co-develop strategies for incorporating systems thinking into their professional and personal worlds.",
    audience: "All learners",
    concepts: "Habits of a Systems Thinker, Behavior Over Time Graphs",
    downloads: [],
    sections: [
      {
        path: "view",
        component: Resource90.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-w",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "t-botg",
      "lg-he",
      "lg-pl",
      "for-leaders",
    ],
  },
  {
    groups: ["template"],
    path: "habits-single-page-mandarin",
    name: "系统思考者的习惯: 单页版本, 普通话翻译",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "Our single-page version of the Habits of a Systems Thinker, translated into Mandarin.",
    audience: "All learners",
    concepts:
      "All the Habits of a Systems Thinker and all systems thinking tools",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/onepage-habits-mandarin.pdf",
        label: "系统思考者的习惯: 单页版本, 普通话",
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource91.Section01,
      },
    ],
    contentTags: [
      "l-zh",
      "c-t",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-se",
      "lg-pe",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-students",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["template"],
    path: "habits-single-page-turkish",
    name: "Sistem Düşünürünün Alışkanlıkları: Tek Sayfa Versiyonu, Türkçe Tercüme",
    icons: [
      {
        id: 1,
        name: "templates-icon",
        source: "/images/resources/shared/templates-icon.svg",
      },
    ],
    content:
      "Our single-page version of the Habits of a Systems Thinker, translated into Turkish.",
    audience: "All learners",
    concepts:
      "All the Habits of a Systems Thinker and all systems thinking tools",
    downloads: [
      {
        id: 1,
        url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/onepage-habits-turkish.pdf",
        label: "Sistem Düşünürünün Alışkanlıkları: tek sayfa, Türkçe", 
      },
    ],
    sections: [
      {
        path: "downloads",
        component: Resource92.Section01,
      },
    ],
    contentTags: [
      "l-tr",
      "c-t",
      "h-bp",
      "h-cot",
      "h-cp",
      "h-cn",
      "h-mm",
      "h-cif",
      "h-la",
      "h-sgb",
      "h-cc",
      "h-sa",
      "h-sta",
      "h-td",
      "h-a",
      "h-mc",
      "lg-se",
      "lg-pe",
      "lg-he",
      "lg-ae",
      "lg-pl",
      "for-students",
      "for-teachers",
      "for-leaders",
    ],
  },
  {
    groups: ["webinar"],
    path: "os-using-loi-to-navigate-hard-conversations",
    name: "Open Studio Webinar Recording: Using the Ladder of Inference to Navigate Hard Conversations",
    icons: [
      {
        id: 1,
        name: "webinar-icon",
        source: "/images/resources/shared/webinar-icon.svg",
      },
    ],
    content:
      "What can you do when you find yourself in a hard conversation? In Waters Center's November 2024 Open Studio Webinar, join Systems Thinking Specialists Melanie Houston and Alexandria Sedar in exploring this question using dialogue, conflict transformation, and systems thinking principles. Through examples, reflection, and opportunities for application, we will experience how the ladder of inference can be used to increase understanding and introduce intentionality into our communication practices.",
    audience: "All learners",
    concepts: "Habits of a Systems Thinker, Ladder of Inference",
    downloads: [],
    sections: [
      {
        path: "view",
        component: Resource93.Section01,
      },
    ],
    contentTags: [
      "l-en",
      "c-w",
      "h-bp",
      "h-sta",
      "t-loi",
      "lg-se",
      "lg-he",
      "lg-pl",
      "for-leaders",
    ],
  },
];

export function getResourcesNav({ resource, section }) {
  return getCurrentNavSetup(
    [
      NavLevel(resource, () => resourceList, "resource"),
      NavLevel(section, () => resource.sections, "section"),
    ],
    null,
    false,
  );
}
