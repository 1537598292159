import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <div className="template-block">
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/6luNHPf-tqs?rel=0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>

    <p>
    In Waters Center's October 2024 Open Studio Webinar, we collectively consider how systems thinking can be used to strengthen our efforts to advance equity. Melanie Houston, Waters Center Systems Thinking Specialist, will share her experience and reflections from real-world applications of systems thinking in equity-promoting efforts. Participants will have an opportunity to apply systems thinking as they co-develop strategies for incorporating systems thinking into their professional and personal worlds.
    </p>
  </div>
);
