import React, { useState, useEffect } from "react";
import { resourceList } from "components/resources";
import { FilterBar } from "components/resources/resource-parts/filter-bar";
import { getSessionAuth } from "components/shared/session";
import { Header } from "components/shared/header";
import { Footer } from "components/shared/footer";
import Helmet from "react-helmet";

export const ResourceCatalog = () => {
  const { user } = getSessionAuth();

  const getAuth = (user) => ({
    divClass: !user ? "is-disabled" : "",
    buttonClass: !user ? "block" : "none",
  });

  const [selectedTags, setSelectedTags] = React.useState([]);

  const [filteredResources, setFilteredResources] = useState([]);

  useEffect(() => {
    if (!selectedTags.length) {
      setFilteredResources(resourceList);
      return;
    }

    const filteredResources = resourceList.filter((resource) =>
      selectedTags.every((tag) => resource.contentTags.includes(tag)),
    );

    setFilteredResources(filteredResources);
  }, [selectedTags, resourceList]);

  const handleTagChange = (tagId) => {
    const tagExists = selectedTags.includes(tagId);

    if (!tagExists) {
      setSelectedTags([...selectedTags, tagId]);
    } else {
      const updatedTags = [...selectedTags];
      updatedTags.splice(selectedTags.indexOf(tagId), 1);
      setSelectedTags(updatedTags);
    }
  };

  return (
    <div className="progress-margin">
      <Header />
      <div className="content">
        <Helmet>
          <title>Systems Thinking Resources</title>
          <meta
            name="description"
            content="Informative articles from various sources."
          />
          <meta
            property="og:title"
            content="Articles – Systems Thinking Resources"
          />
          <meta
            property="og:description"
            content="Informative articles from various sources."
          />
          <meta
            property="og:image"
            content="https://thinkingtoolsstudio.org/images/resources/shared/articles-icon-blue.svg"
          />
          <meta
            property="og:url"
            content="https://thinkingtoolsstudio.org/resources/articles"
          />
          <meta name="twitter:image:alt" content="Systems Thinking Articles" />
        </Helmet>

        <FilterBar handleTagChange={handleTagChange} />

        <h1 className="resource-home-title mobilecenter">
          Systems Thinking Resources
        </h1>

        <p>
          Explore a variety of systems thinking resources including Templates,
          Lesson Plans, Facilitation Guides, and Webinars. Use the filter bar on
          the right side of the screen to filter resources by type, topic, or
          audience.
        </p>

        <div className="resource-cards">
          {filteredResources
            .sort((a, b) =>
              a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0,
            )
            .map((r) => (
              <div key={r.path} className="r-content">
                <div className="r-info">
                  <h2>
                    <a
                      className={getAuth(user).divClass}
                      href={`/resources/${r.path}/${r.sections[0].path}`}
                    >
                      {r.name}
                    </a>
                  </h2>

                  <p className="r-summary">{r.content}</p>

                  <p className="r-concepts">
                    <b>ST Habits & Tools:</b> {r.concepts}
                  </p>

                  <p className="r-audience">
                    <b>Audience:</b> {r.audience}
                  </p>

                  <a href="https://waterscenterst.org/login">
                    <button
                      style={{ display: getAuth(user).buttonClass }}
                      className="auth-button"
                    >
                      Log in or Sign Up to View This Content
                    </button>
                  </a>
                </div>

                <div className="r-icons">
                  {r.icons.map((i) => (
                    <img
                      key={i.id}
                      className="resource-icon"
                      src={i.source}
                      alt={i.name}
                    />
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};
