import { useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

const ScrollToTop = (props) => {
  const prevProps = useRef(props);

  useEffect(() => {
    if (props.location.pathname !== prevProps.current.location.pathname) {
      window.scrollTo(0, 0);
    }
    prevProps.current = props;
  }, [props]);

  return props.children;
};

export default withRouter(ScrollToTop);
ScrollToTop.propTypes = {
  location: PropTypes.any,
  children: PropTypes.any,
};
